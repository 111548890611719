import axios from "axios";
import { HomeItem } from "providers/Admin/AdminActionTypes";
import { CustomerActions } from "providers/Customer";
import { CustomerActionTypes, Customer, CustomersResponse, CustomerResponse } from "providers/Customer/CustomerActionTypes";
import { UserAppState } from "providers/User/UserReducer";
import { HTTP_API_URL } from "util/Constants";
import { BaseService } from "./BaseService";

export interface PostCreateCustomer {
  customer: Customer;
}

export interface PostUpdateCustomer {
  customer: Customer;
}

export interface PostDeactivateCustomer {
  customer: Customer;
}

export default class CustomerService extends BaseService {
  /**
 * Using static field for debouncing the mark as read API call.
 */
  private static timeout: any | null = null;

  private readonly dispatch: React.Dispatch<CustomerActionTypes>;

  constructor(
    state: UserAppState,
    dispatch: React.Dispatch<CustomerActionTypes>
  ) {
    super(state);
    this.dispatch = dispatch;
  }

  fetchCustomers(
    page: string = "0",
    home: HomeItem | undefined,
    activeOnly: boolean | undefined,
    query: string | undefined
  ): Promise<CustomersResponse> {
    return new Promise(async (resolve, reject) => {
      if (page === "0") {
        this.dispatch(CustomerActions.clearCustomers());
      }

      const url = new URL(HTTP_API_URL + "/customers");
      url.searchParams.append("pagesize", "20");
      url.searchParams.append("page", page);
      if (home) {
        url.searchParams.append("homeId", home.id.toString());
      }
      if (activeOnly) {
        url.searchParams.append("active", activeOnly ? "true" : "false");
      }
      if (query) {
        url.searchParams.append("nameSearch", query);
      }

      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );
        response.data.customers.sort((a: Customer, b: Customer) => {
          // @ts-ignore
          return a.lastName - b.lastName;
        });

        this.dispatch(
          CustomerActions.onGetCustomersResponse(response.data.customers)
        );
        resolve(response.data as CustomersResponse);
      } catch (e) {
        reject(e);
      }
    });
  }

  fetchCustomer(customerId: number): Promise<CustomerResponse> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/customers/" + customerId);
      try {
        const response = await axios.get(
          url.toString(),
          this.addAuthorizationHeaders()
        );

        this.dispatch(CustomerActions.onGetCustomerResponse(response.data));
        resolve({ customer: response.data });
      } catch (e) {
        reject(e);
      }
    });
  }

  postCreateCustomer(request: PostCreateCustomer): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/customers");
      try {
        const response = await axios.post(
          url.toString(),
          request.customer,
          this.addAuthorizationHeaders()
        );

        console.log('*** postCreateCustomer SUCCESS', response);

        this.dispatch(CustomerActions.onUpdatedCustomerResponse(response.data));
        resolve(response.data);
      } catch (e) {
        console.log('*** postCreateCustomer FAIL', e);
        reject(e);
      }
    });
  }

  postUpdateCustomer(request: PostUpdateCustomer): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url = new URL(HTTP_API_URL + "/customers/" + request.customer.id);
      try {
        const response = await axios.put(
          url.toString(),
          request.customer,
          this.addAuthorizationHeaders()
        );

        console.log('*** postUpdateCustomer SUCCESS', response);

        this.dispatch(CustomerActions.onUpdatedCustomerResponse(response.data));
        resolve(response.data);
      } catch (e) {
        console.log('*** postUpdateCustomer FAIL', e);
        reject(e);
      }
    });
  }
 
  postDeactivateCustomer(request: PostDeactivateCustomer): Promise<any> {
    return new Promise(async (resolve, reject) => {

      const url = new URL(
        HTTP_API_URL + "/customers/" + request.customer.id + "/deactivate"
      );
      try {
        const response = await axios.post(
          url.toString(),
          request.customer,
          this.addAuthorizationHeaders()
        );

        console.log('*** postDeactivateCustomer SUCCESS', response);

        this.dispatch(
          CustomerActions.onDeactivateCustomerResponse(response.data)
        );
        resolve(response);
      } catch (e) {
        console.log('*** postDeactivateCustomer FAIL', e);
        reject(e);
      }
    });
  }
}